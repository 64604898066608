import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import {List} from 'antd'

import {item, row, icon, title} from './PostList.module.scss'

/**
 * Render the PostList component.
 * @see                         {@link https://ant.design/components/list/}
 * @author                      Content Pilot
 * @param  {object}  props       The component attributes as props.
 * @param  {Array}   props.posts The array of post to render.
 * @return {Element}             The PostList component.
 */
export default function PostList({posts}) {
  return (
    <List
      grid={{gutter: 16, md: 2, lg: 2, xl: 2, xxl: 2}}
      dataSource={posts}
      renderItem={(post) => {
        return (
          <List.Item key={post.title} className={item}>
            <Link to={post.slug}>
              <div className={row}>
                <div className={icon}>
                  <StaticImage
                    src="../../../images/color-bars.png"
                    width={60}
                    alt="Colors bar icon"
                  />
                </div>
                <h2 className={title}>{post.title}</h2>
              </div>
            </Link>
          </List.Item>
        )
      }}
    />
  )
}

PostList.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object)
}

export const query = graphql`
  fragment PostListFields on WpPost {
    id
    title
    excerpt
    date(formatString: "MMMM D, YYYY")
    slug
  }
`
