import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

import Content from 'organisms/Content'
import Main from 'organisms/Main'
import MenuSecondary from 'molecules/MenuSecondary'
import PostList from 'organisms/PostList'
import SEO from 'molecules/Seo'
import PageTitle from 'atoms/PageTitle'

import {post} from './PostArchive.module.scss'

/**
 * Template Post Archive
 * @author                     Content Pilot
 * @param  {object}  props      The component attributes as props.
 * @param  {object}  props.data The page data.
 * @return {Element}            The Post Archive template.
 */
export default function ArchivePost({data}) {
  const posts = data?.allWpPost?.nodes ?? []

  return (
    <Content>
      <SEO title="Blog" />
      <MenuSecondary />
      <PageTitle title="Blog" fullWidth />
      <Main className={post}>
        {!posts.length ? (
          <p>
            No blog posts found. Add posts to your WordPress site and
            they&apos;ll appear here.
          </p>
        ) : (
          <PostList posts={posts} />
        )}
      </Main>
    </Content>
  )
}

ArchivePost.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      nodes: PropTypes.array
    })
  })
}

export const query = graphql`
  query PostAll($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: {fields: date, order: DESC}
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        title
        excerpt
        slug
      }
    }
  }
`
